.home{
    background-image: url('../../../../public/assets/images/background.png');
    /* background-size: cover;  */
    background-position: center; 
    /* background-repeat: no-repeat;  */
    min-height: 100vh;
    text-transform: capitalize;
}
.home-parent{
    padding: 30px 0px;
    overflow-x: hidden !important;
}
section{
    padding: 30px 0px;
}
.top-secton{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: capitalize;
}
.section-title{
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
}
.section-sub-title{
    color:rgba(139, 139, 139, 1);
    font-weight: 400;
    font-size: 1rem;
}
.see-work-container{
    display: flex;
    text-decoration: none;
    /* align-items: center; */
}
.see-work-container p{
   color:rgba(18, 18, 18, 1) ;
   font-weight: 600;
   font-size: 1rem;
}
.see-work-container img{
    padding-left: 5px;
    margin-top: 8px;
    height: 10px;
}
/* ======================================== work =================================== */
.work-title{
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
    font-size: 1.5rem;
    margin-top: 15px;
}
.work-parent{
    background-color: rgba(0, 0, 0, 1);
    border-radius: 16px;
}
.product-title{
    color: rgba(255, 255, 255, 1);
    font-weight: 400;
    font-size: .7rem;
}
.product-description{
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    font-size: 2rem;  
}
.small-break-line{
    width: 30px;
    height: 1px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 16px;
}


.product-img img{
    width: 100% !important;
    height: 445px;
    object-fit: contain;
}

/* ======================================== services =================================== */
.service{
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 65px !important;
}
.service-titel{
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
    font-size: 1.5rem;
    /* margin-top: 15px; */
}
.service-sub-titel{
    color: rgba(139, 139, 139, 1);
    text-transform: initial;
}
.small-break-line2{
    margin-top: 15px;
    width: 50px;
    height: 2px;
    border-radius: 16px;
    background-color: rgba(139, 139, 139, 1);
}

/* ======================================== contact us =================================== */
.form-control,select{
    padding: 12px !important ;
    border-radius: 16px !important;
    color: rgba(139, 139, 139, 1) !important;
    border-color: rgba(139, 139, 139, 1) !important;
}
::placeholder {
    color: rgba(139, 139, 139, 1) ;
    font-size: .8rem; 
  }
.submit-button{
    background-color: rgba(18, 18, 18, 1) !important;
    border-radius: 16px !important;
    padding: 15px !important;
}


.input-placeholder-wrapper {
    position: relative;
}

.input-placeholder-wrapper input[type="datetime-local"] {
    position: relative;
    z-index: 1;
    background-color: transparent;
}

.input-placeholder-wrapper .custom-placeholder {
    position: absolute;
    top: 50%;
    left: 20px;
    font-size: 14px;
    transform: translateY(-50%);
    color: gray;
    pointer-events: none;
    z-index: 0;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

/* Hide custom placeholder when input is focused or has a valid date */
.input-placeholder-wrapper input[type="datetime-local"]:focus + .custom-placeholder,
.input-placeholder-wrapper input[type="datetime-local"].has-value + .custom-placeholder {
    opacity: 0;
}

input[type="datetime-local"]::-webkit-datetime-edit-year-field,
input[type="datetime-local"]::-webkit-datetime-edit-month-field,
input[type="datetime-local"]::-webkit-datetime-edit-day-field,
input[type="datetime-local"]::-webkit-datetime-edit-hour-field,
input[type="datetime-local"]::-webkit-datetime-edit-minute-field,
input[type="datetime-local"]::-webkit-datetime-edit-second-field {
    opacity: 0;
}

input[type="datetime-local"].has-value::-webkit-datetime-edit-year-field,
input[type="datetime-local"].has-value::-webkit-datetime-edit-month-field,
input[type="datetime-local"].has-value::-webkit-datetime-edit-day-field,
input[type="datetime-local"].has-value::-webkit-datetime-edit-hour-field,
input[type="datetime-local"].has-value::-webkit-datetime-edit-minute-field,
input[type="datetime-local"].has-value::-webkit-datetime-edit-second-field {
    opacity: 1;
    color: gray;

    
}
input[type="datetime-local"]::-webkit-datetime-edit {
    color: transparent;
}

input[type="datetime-local"].has-value::-webkit-datetime-edit {
    color: gray;
}

.ios-cap{
    text-transform: lowercase;
}