@media only screen and (max-width:  768px) {
    .section-title{
        font-size: 1.7rem;
    }
    .section-sub-title{
        font-size: .9rem;
    }

    .product-title{
        font-size: .9rem;
    }
    .work-title{
        font-size: 1.3rem; 
    }
    .product-description{
        font-size: 1.3rem;
    }
    .product-img img{
        height: 300px;
    }
    .service{
        height: 300px;
        padding: 0px 40px !important;
    }
}