.navbar-brand{
    width: 200px ;
}
.lets-talk{
    background-color: rgba(18, 18, 18, 1);
    border-radius: 12px;
    color:rgba(255, 255, 255, 1);
    padding: 7px 15px;

}
.nav-link{
    font-weight: 500 !important;
}
.navbar-toggler {
    border: none;
}

@media only screen and (max-width:  768px) {
    .navbar-brand img {
        width: 100px; 
        height: auto; 
      }
      .navbar-toggler {
        border: none !important;
        outline: none !important;
        padding: 0;
      }
  
    .navbar-nav .nav-link {
        text-align: center;
        border-bottom: 1px solid rgba(127, 127, 127, 1); 
        padding: 20px; 
        font-weight: 500 !important;
        color: rgba(18, 18, 18, 1);
      }
      .navbar-nav .nav-link:last-child {
        border-bottom: none; /* Remove border-bottom from the last Nav.Link */
      }
}