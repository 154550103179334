footer{
    background: rgba(18, 18, 18, 1);
    padding: 15px;
    color: rgba(255, 255, 255, 1);
}


.footer-icons{
    display:flex ;
    align-items: center;
}

.footer-icons img{
    width: 17px;
    height: 17px;
    margin-right: 15px;
}
.contact-info{
    display: flex;
    flex-direction: row;
    justify-content: center ;
    align-items: center ;
}
.img-footer{
    height: 40px;
    width: 130px;
}
@media only screen and (max-width:  768px) {
  
    .contact-info {
      flex-direction: column;
    }
}